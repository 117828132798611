import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BaseLayout from '../components/templates/BaseLayout';
import SEO from '../components/atoms/utility/seo';
import Spacer from '../components/atoms/utility/Spacer';
import ShopHopHeroContent from '../components/molecules/ShopUpHeroContent';
import ShopHopApplicant from '../components/organisms/ShopHopApplicant';

export default function ShopHopPage({
  data: {
    heroImg,
    mobileHeroImg,
    panelOneImg,
    panelThreeImg,
    panelFourImg,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
  },
}) {
  const partnerImages = [logo1, logo2, logo3, logo4, logo6, logo7];
  return (
    <BaseLayout
      heroContent={ShopHopHeroContent}
      mobileHeroImg={mobileHeroImg}
      heroImg={heroImg}
      heroAlt="Showcasing our products."
      partnerImages={partnerImages}
    >
      <SEO
        title="Shop Hop | flockx Local Community Boost"
        keywords={[
          'small business empowerment',
          'enterprise marketing',
          'local digital marketing',
          'business solutions',
          'product showcase',
          'community growth',
          'innovative technology',
          'success unlock',
          'customer retention',
        ]}
        description="Shop Hop - Unlock your business potential with flockx.io. Drive more customers to events & specials"
      />

      <div>
        <Spacer sizeY={1} className="block xl:block" />
        <Spacer className="block sm:hidden" />

        <div className="flex flex-col lg:grid lg:gap-24 grid-cols-12 justify-center items-center">
          <div className="col-span-6 order-2 lg:order-1 max-w-[700px] mx-auto">
            <GatsbyImage
              image={getImage(panelOneImg)}
              alt="transform off-peak to prime time"
              className="rounded-xl"
            />
          </div>
          <div className="col-span-6 text-center lg:text-left mb-20 lg:mb-0 px-4 order-1 lg:order-2 max-w-2xl mx-auto">
            <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2] mb-5">
              Transform Off-Peak to
              {' '}
              <span className="text-default">Prime Time</span>
            </h3>
            <p className="text-lg sm:text-2xl md:text-3xl lg:text-3xl">
              Convert least popular times into customer-favorite hours by
              advertising your activity to your local community.
            </p>
          </div>
        </div>

        <Spacer className="block sm:hidden" />
        <Spacer sizeY={2} />

        <div className="lg:grid gap-10 lg:gap-24 grid-cols-12 justify-center items-center">
          <div className="col-span-6 text-center lg:text-left mb-20 lg:mb-0 px-4 max-w-2xl mx-auto">
            <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2] mb-5">
              <span className="text-secondary-orange">Promote</span>
              {' '}
              your
              Events, Specials and Activities
            </h3>
            <p className="text-lg sm:text-2xl md:text-3xl lg:text-3xl mb-8 lg:mb-12">
              Join a local network of digital billboards and displays around
              your city. Delivering a 10x more cost-effective impact.
            </p>
          </div>
          <div className="col-span-6 max-w-[700px] mx-auto">
            <GatsbyImage
              image={getImage(panelThreeImg)}
              alt="promote your events"
              className="rounded-xl"
            />
          </div>
        </div>

        <Spacer className="block sm:hidden" />
        <Spacer sizeY={2} />

        <div className="flex flex-col lg:grid lg:gap-24 grid-cols-12 justify-center items-center">
          <div className="col-span-6 order-2 lg:order-1 max-w-[700px] mx-auto">
            <GatsbyImage
              image={getImage(panelFourImg)}
              alt="kick-start campaign"
              className="rounded-xl"
            />
          </div>
          <div className="col-span-6 text-center lg:text-left mb-20 lg:mb-0 px-4 order-1 lg:order-2 max-w-2xl mx-auto">
            <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2] mb-5">
              Kick-start a Campaign by
              {' '}
              <span className="text-highlight">Sharing a Link</span>
            </h3>
            <p className="text-lg sm:text-2xl md:text-3xl lg:text-3xl">
              Launch pinpoint campaigns effortlessly by sharing a link to your
              business. No complexity, just simplicity.
            </p>
          </div>
        </div>
        <Spacer sizeY={2} className="block xl:block" />
        <Spacer className="block sm:hidden" />
        <div className="mx-auto w-full flex items-center justify-center">
          <ShopHopApplicant />
        </div>
        <Spacer className="block sm:hidden" />
        <Spacer />
      </div>
    </BaseLayout>
  );
}

export const query = graphql`
  query ShopHopPageQuery {
    heroImg: file(name: { eq: "business-hero" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    mobileHeroImg: file(name: { eq: "business-hero-mobile" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    panelOneImg: file(name: { eq: "transform-business" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    panelThreeImg: file(name: { eq: "map-promo" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    panelFourImg: file(name: { eq: "how-to" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    logo1: file(name: { eq: "grand-rapids-chamber" }) {
      childImageSharp {
        gatsbyImageData(height: 75)
      }
    }
    logo2: file(name: { eq: "santa-parade-logo" }) {
      childImageSharp {
        gatsbyImageData(height: 75)
      }
    }
    logo3: file(name: { eq: "the-right-place-logo" }) {
      childImageSharp {
        gatsbyImageData(height: 75)
      }
    }
    logo4: file(name: { eq: "city-of-grand-rapids-logo" }) {
      childImageSharp {
        gatsbyImageData(height: 75)
      }
    }
    logo5: file(name: { eq: "grand-rapids-chamber-of-commerce-logo" }) {
      childImageSharp {
        gatsbyImageData(height: 75)
      }
    }
    logo6: file(name: { eq: "art-prize-logo" }) {
      childImageSharp {
        gatsbyImageData(height: 75)
      }
    }
    logo7: file(name: { eq: "uptown-logo" }) {
      childImageSharp {
        gatsbyImageData(height: 75)
      }
    }
  }
`;
