import React from 'react';
import Button from '../atoms/inputs/Button';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

export default function ShopHopHeroContent() {
  const { shopHopImage } = useStaticQuery(graphql`
    query ShopHopHeaderQuery {
      shopHopImage: file(name: { eq: "shop-hop1" }) {
        childImageSharp {
          gatsbyImageData(height: 700)
        }
      }
    }
  `);

  return (
    <>
      <div className="grid grid-cols-1 xl:grid-cols-16 -mt-[30%] sm:mt-[5%] lg:mt-0">
        {/* grid one image on the left */}
        <div className="text-center sm:text-left sm:mt-6 lg:mt-0 xl:col-span-9 w-full">
          <div className="mb-10 md:mb-0">
            <h1
              className={
                'text-4xl sm:text-4xl md:text-5xl leading-[1.3] md:leading-[1.4] font-light text-white mb-2 sm:mb-4 md:max-w-6xl lg:max-w-6xl xl:max-w-6xl'
              }
            >
              <span className="block">
                Welcome, <span className="font-bold">Uptown Businesses.</span>
              </span>
              <span className="hidden sm:block">Partner of Shop Hop & Small</span>
              <span className="hidden sm:block">Business Saturday, 2023</span>
            </h1>

            <h2 className="block sm:hidden lg:block text-lg sm:text-xl md:leading-[1.3] mb-4 md:mb-4 lg:mb-8 text-white mx-auto font-light max-w-[340px] sm:max-w-sm lg:max-w-3xl xl:max-w-xl sm:mx-0">
              Unleash the potential of your business by driving more customers
              to weekly events & specials.
            </h2>
            <a
              id="free-consultation-btn"
              href="#learn-more"
              className="mb-4 md:mb-6 inline-block lg:hidden 2xl:inline-block"
            >
              <Button label="Learn More" />
            </a>
          </div>
        </div>
        {/* grid two image on the right */}
        <div className="hidden xl:block p-4 w-full h-full col-span-7">
          <GatsbyImage
            image={getImage(shopHopImage)}
            alt="people shopping at shop hop"
            className="w-full"
            objectFit="contain"
          />
        </div>
      </div>
    </>
  );
}
