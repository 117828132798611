import React, { useState } from 'react';
import { Link } from 'gatsby';
import Button from '../atoms/inputs/Button';

export default function ShopHopApplicant() {
  const [name, setName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [phone, setPhone] = useState('');

  const inputStyles =
    'block w-full px-4 py-3 h-12 appearance-none rounded-3xl border-0 text-base bg-grey-gradient text-grey-25 placeholder-grey-25 focus:outline-none focus:ring-1 focus:ring-highlight shadow-md';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!name || !businessName || !email || !phone) {
      return setErrorMessage('Please fill in all required fields');
    }

    try {
      await fetch('/.netlify/functions/es-shop-hop-applicant', {
        method: 'POST',
        body: JSON.stringify({
          name,
          businessName,
          email,
          phone,
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            setName('');
            setBusinessName('');
            setEmail('');
            // setUrl('');
            setPhone('');
            setErrorMessage('Thank you for your submission');
            setIsLoading(false);
          }

          if (res.status === 500) {
            setErrorMessage('This email has already been entered');
            setIsLoading(false);
          }
          return res.json();
        })
        .catch((err) => console.log(err.message));
    } catch (error) {
      console.error(error);
      setErrorMessage('An error occurred. Please try again later.');
      setIsLoading(false);
    }
  };

  return (
    <div
      id="learn-more"
      name="shop-hop-applicant-sign-up-form"
      className="relative z-10 text-center w-full bg-white px-0 lg:px-10 py-4 xl:py-8 rounded-3xl"
    >
      <div className="max-w-5xl text-center mx-auto mb-8 lg:mb-8">
        <h3 className="text-3xl md:text-4xl lg:text-5xl text-light-grey font-light lg:leading-[62.5px] mb-4">
          <span className="text-highlight">Get Your Free Uptown Promotion</span>
        </h3>
        <p className="text-lg lg:text-xl xl:text-2xl max-w-xl mx-auto">
          Submit your details with <span className="font-bold">flockx</span> to
          access your free promotion and start building your business profile
          today.
        </p>
      </div>
      <form onSubmit={handleSubmit} className="mx-auto max-w-lg">
        <div className="flex flex-col  items-start justify-start w-full">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className={inputStyles + ' mb-4'}
            placeholder="Name"
          />

          <input
            type="text"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            required
            className={inputStyles + ' mb-4'}
            placeholder="Business Name"
          />

          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={inputStyles + ' mb-4'}
            placeholder="Email"
          />

          {/* <input
            type="tel"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className={inputStyles + ' mb-4'}
            placeholder="Website or URL event info"
          /> */}
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            className={inputStyles + ' mb-4'}
            placeholder="Phone Number"
          />
        </div>
        <p
          className={`my-3 text-xs lg:text-base sm:mt-3 text-grey-25 font-light max-w-xs md:max-w-[unset] mx-auto`}
        >
          By providing your information, you agree to our{' '}
          <Link to="/legal/terms" className=" text-highlight">
            terms of service
          </Link>
          .
        </p>
        <div className="mx-auto flex items-center justify-center h-full relative">
          <Button
            type="submit"
            label="Submit"
            className="min-w-[200px]"
            isLoading={isLoading}
            minWidth={213}
            id="business-applicant-button"
          />
        </div>
        {errorMessage && (
          <p className="text-center my-4 lg:my-6 text-default text-xl lg:text-2xl">
            {errorMessage}
          </p>
        )}
      </form>
    </div>
  );
}
